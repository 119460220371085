* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

$logoColorPath: "./assets/img/logo-text.svg";
$hamburgerPath: "./assets/img/hamburger.svg";
$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: #c43d4b;
$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;

$border-radius: 0.1rem;
$border-radius-rounded: 0.75rem;

$theme-color-1: #224082;
$theme-color-2: #3498ff;
$theme-color-3: #3498ff8c;
$theme-color-4: #2296f3;
$theme-color-imgage-2: linear-gradient(90deg, #6384d3, #365293);
$foreground-color: white;
$primary-color: #212121;
$button-text-color: #fff;
$input-background: white;
$active-text: #0f6faa;

$bg1: "./assets/img/p1-1.svg";
$bg2: "./assets/img/p1-2.svg";
$bg3: "./assets/img/p1-3.svg";
$bg4: "./assets/img/p1-4.svg";
$bg5: "./assets/img/p1-5.svg";
$bg6: "./assets/img/p1-6.svg";
$bg7: "./assets/img/p1-7.svg";
$bg8: "./assets/img/p1-8.svg";
$bg9: "./assets/img/p1-9.svg";
$p2-1: "./assets/img/p2-1.svg";
$p2-2: "./assets/img/p2-2.svg";
$p2-3: "./assets/img/p2-3.svg";
$hdsd-1: "./assets/img/hdsd-1.svg";
$hdsd-2: "./assets/img/hdsd-2.svg";
$hdsd-3: "./assets/img/hdsd-3.svg";
$hdsd-4: "./assets/img/hdsd-4.svg";
$hdsd-5: "./assets/img/hdsd-5.svg";
$hdsd-6: "./assets/img/hdsd-6.svg";
$google-store: "./assets/img/google-store.svg";
$apple-store: "./assets/img/apple-store.svg";
$list-1: "./assets/img/list-1.svg";
$list-2: "./assets/img/list-2.svg";
$list-3: "./assets/img/list-3.svg";
$p1: "./assets/img/p3-1.svg";
$p2: "./assets/img/p3-2.svg";
$p3: "./assets/img/p3-3.svg";
$p4: "./assets/img/p3-4.svg";
$p5: "./assets/img/p4-1.svg";
$stock: "./assets/img/stock.svg";
$data: "./assets/img/data.svg";
$bg-icon: "./assets/img/bg-icon.svg";
$support: "./assets/img/support.png";

@font-face {
  font-family: "MyriadPro";
  src: url("MyriadPro-Regular.eot");
  src: url("MyriadPro-Regular.eot?#iefix") format("embedded-opentype"), url("MyriadPro-Regular.woff2") format("woff2"),
    url("MyriadPro-Regular.woff") format("woff"), url("MyriadPro-Regular.ttf") format("truetype"),
    url("MyriadPro-Regular.svg#MyriadPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "UTMAvoBold";
  src: url("UTMAvoBold.eot");
  src: url("UTMAvoBold.eot?#iefix") format("embedded-opentype"), url("UTMAvoBold.woff2") format("woff2"),
    url("UTMAvoBold.woff") format("woff"), url("UTMAvoBold.ttf") format("truetype"),
    url("UTMAvoBold.svg#UTMAvoBold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "MyriadProBoldCondensed";
  src: url("Myriad-Pro-Bold-Condensed.eot");
  src: url("Myriad-Pro-Bold-Condensed.eot?#iefix") format("embedded-opentype"),
    url("Myriad-Pro-Bold-Condensed.woff2") format("woff2"), url("Myriad-Pro-Bold-Condensed.woff") format("woff"),
    url("Myriad-Pro-Bold-Condensed.ttf") format("truetype"),
    url("Myriad-Pro-Bold-Condensed.svg#Myriad Pro") format("svg");
}

@font-face {
  font-family: "MyriadProBold";
  src: url("Myriad-Pro-Bold.eot");
  src: url("Myriad-Pro-Bold.eot?#iefix") format("embedded-opentype"), url("Myriad-Pro-Bold.woff2") format("woff2"),
    url("Myriad-Pro-Bold.woff") format("woff"), url("Myriad-Pro-Bold.ttf") format("truetype"),
    url("Myriad-Pro-Bold.svg#Myriad Pro") format("svg");
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.fb_dialog {
  bottom: 1000pt !important;
  z-index: 999 !important;
}
.fb_dialog_content {
  iframe {
    bottom: 124px;
  }
}


.react-responsive-modal-modal {
  padding: 0 !important;
}
.react-responsive-modal-closeButton {
  outline: unset;
  opacity: 0.5 !important;
  top: 0 !important;
  right: 0 !important;
  background-color: #fff !important;
}

body {
  height: 100%;
  font-family: "MyriadPro", sans-serif;
  // font-size: 0.8rem;
  font-size: calc(0.75em + 1vmin);
  font-weight: 400;
  color: #212121;
  background: $foreground-color;
  box-sizing: border-box;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}
.mt-50px {
  margin-top: 50px;
}
.no-margin {
  margin: 0 !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.error-page {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .item {
    width: 100vh;
    text-align: center;
  }
  img {
    height: 260px;
  }
}

.modal-require-trial {
  &__head {
    font-size: 1.15em;
    line-height: 1.5;
    font-weight: 700;
    padding: 15px 25px;
    color: #666666;
    background-color: #f2f2f2;
  }
  &__body {
    display: inline-flex;
    padding: 50px;

    .disable {
      color: #000;
      font-weight: 900;
    }

    .form {
      .group-btn {
        margin-bottom: 1.5rem;

        button {
          margin-top: 1.5rem;
        }
      }
    }
    .contact {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;

      .text {
        font-size: 20px;
      }

      h4 {
        font-size: 24px;
        letter-spacing: 0px;
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        line-height: 1.4;
        margin: 0 0 1.5rem;

        span {
          color: #1abc9c;
        }
      }
      p {
        // font-family: "Open Sans", sans-serif;
        font-size: 16px;
        line-height: 24px;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        margin-bottom: 0;
      }
      h2 {
        line-height: 1.4;
        margin: 0 0 1.5rem;
        display: block;
        margin-block-start: 0.2em;
        margin-block-end: 0.3em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
    }
  }
}

.as-btn {
  background: linear-gradient(90deg, #7b27ab, #ae69d1);
  color: #fcfcfc;
  // font-family: "UTMAvoBold", sans-serif;
  font-family: "MyriadProBold", sans-serif;

  font-size: 16px;
  padding: 10px 35px;
  width: fit-content;
  margin: 0 auto;
  border-radius: 10px;
  border: 1px solid #7b27ab;

  &:hover {
    cursor: pointer;
    background: transparent;
    color: #7b27ab;
  }
}

.bg-logo-icon {
  display: inline;
  margin-left: -15px;
  margin-right: 15px;

  .bg {
    background: url($bg-icon) no-repeat top left;
    width: 85px;
    height: 82px;
    text-align: center;
  }
  .stock-logo {
    background: url($stock) no-repeat top left;
    width: 45px;
    height: 70px;
    margin: 0 auto;
    background-position: 0px 10px;
  }
  .data-logo {
    background: url($data) no-repeat top left;
    width: 45px;
    height: 70px;
    margin: 0 auto;
    background-position: 0px 10px;
  }
}

.store {
  position: relative;
  display: flex;
  justify-content: center;

  .google-store {
    display: inline-block;
    background: url($google-store) no-repeat top left;
    width: 105px;
    height: 32px;
    cursor: pointer;
    z-index: 4;
  }
  .apple-store {
    display: inline-block;
    background: url($apple-store) no-repeat top left;
    width: 105px;
    height: 32px;
    cursor: pointer;
    z-index: 4;
    background-position: 0px 2px;
  }
}

/** Footer **/
.footer {
  background-color: #000;
  color: #fff;
  padding: 20px 15vw;
  position: relative;
  z-index: 9;

  ul {
    list-style-type: none;
    margin: 0px 20px;

    .title {
      // margin: 10px 0px;
      font-size: 18px;

      p {
        text-transform: uppercase;
      }
    }

    li {
      font-size: 14px;
      // font-family: Roboto, Helvetica, sans-serif;
      font-weight: 400;
      line-height: 1.2;
      letter-spacing: 0.025em;
      margin: 10px;
      flex-basis: 100%;
    }
  }

  &__section-1 {
    display: inline-flex;
    margin-bottom: 20px;
  }
  &__section-2 {
    line-height: 25px;

    .copyright {
      margin-top: 20px;
    }
  }
}
/** End Footer **/

/** Toolbar **/
.toolbar {
  background-color: #fff;
  width: 100%;
  // height: 65px;
  // font-size: 16px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 10;

  .toolbar__navigation {
    display: flex;
    height: 88px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;

    &--logo {
      width: 90px;
      height: 40px;
      background: url($logoColorPath) no-repeat;
      background-position: center center;
      display: inline-block;
      margin: 0 auto;
      margin: 0px 10px;
      cursor: pointer;
    }

    &--menu {
      flex: 0 1 auto;
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: inline-flex;
      list-style: none;
      align-items: center;
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;

      li {
        padding: 0;
        flex: 0 0 auto;
        display: flex;
        align-items: stretch;
      }

      li a {
        text-decoration: none;
        color: $primary-color;
        font-weight: 500;
        color: #11161d;
        padding-right: 25px;
        margin: 0;
        display: flex;
        align-items: center;
        font-size: 15px;
        line-height: 2;
        padding: 0 10px;

        &:hover {
          color: $active-text;
        }
      }

      li a.active {
        color: $active-text;
      }

      .as-btn {
        // padding: 12px 15px 8px 15px;
        // margin-left: 5px;
        // margin-bottom: 5px;
        // // font-family: "MyriadPro", sans-serif;

        // li {
        //   font-size: 1.4vw;
        // }

        color: #fff;
        font-size: 12px;
        border-radius: 100px;
        box-sizing: border-box;
        padding: 0.5em 1.24em;
        letter-spacing: 0.04em;
        transition: all 0.1s ease-in-out;
        line-height: 1;
        margin: auto;
        padding: 0.6em 1.24em;
        font-size: 13px;
        line-height: 1.4;

        &:hover {
          li {
            color: #7b27ab;
          }
        }

        ul li {
          padding: 10px 5px;
        }
      }
    }
  }
}
/** END Toolbar **/

.desktop {
  display: block;
}
.mobile {
  display: none;
}
/** Dashboard **/
.background {
  position: relative;
  z-index: 1;
  background-color: #fcfafa;
  overflow: hidden;

  &__section-1 {
    position: relative;
    min-height: 300px;

    .text1 {
      text-align: center;
      font-size: 4vw;
      color: #fff;
      font-weight: 500;
      margin: 20px 10px;
    }

    .store {
      position: relative;
      display: flex;
      justify-content: center;

      .google-store {
        display: inline-block;
        background: url($google-store) no-repeat top left;
        width: 105px;
        height: 32px;
        cursor: pointer;
        z-index: 4;
      }
      .apple-store {
        display: inline-block;
        background: url($apple-store) no-repeat top left;
        width: 105px;
        height: 32px;
        cursor: pointer;
        z-index: 4;
        background-position: 0px 2px;
      }
    }

    .bg-1 {
      position: absolute;
      background: url($bg1) no-repeat;
      width: 100%;
      height: 1108px;
      top: -65px;
    }
    .bg-2 {
      position: absolute;
      top: 0;
      background: url($bg2) no-repeat;
      width: 100%;
      height: 100%;
    }
    .bg-4 {
      position: absolute;
      top: 0;
      background: url($bg4) no-repeat;
      width: 100%;
      height: 1226px;
      // right: -90px;
      // top: 120px;
      right: -110px;
      top: 150px;
    }

    .ptext {
      padding: 0px 20px;
    }

    .p-1 {
      position: relative;
      background: url($p1) no-repeat;
      width: 70%;
      height: 100%;
      margin: 0 auto;
      top: 10vw;
    }
  }
  &__section-2 {
    position: relative;
    display: inline-flex;
    min-height: 300px;

    .bg-3 {
      display: inline-flex;
      position: relative;
      // left: 19vw;
      // top: 5vh;
      background: url($bg3) no-repeat;
      width: 95vw;
      height: 100%;

      .list-card {
        list-style-type: none;
        padding: 0;

        .card {
          position: relative;
          width: 34vw;
          height: 34vw;
          background-color: $foreground-color;
          border-radius: 14px;
          margin: 20px 10px;
          padding: 10px;
          left: 20vw;
          top: 10vw;

          p {
            font-weight: bold;
            margin-top: 5px;
          }
          span {
            font-size: 12px;
          }
        }
      }

      .bg-6 {
        background: url($bg6) no-repeat top left;
        width: 12vw;
        height: 14vw;
      }
      .bg-7 {
        background: url($bg7) no-repeat top left;
        width: 12vw;
        height: 12vw;
      }
      .bg-8 {
        background: url($bg8) no-repeat top left;
        width: 12vw;
        height: 12vw;
      }
      .bg-9 {
        background: url($bg9) no-repeat top left;
        width: 12vw;
        height: 12vw;
      }
    }
  }
  &__section-3 {
    position: relative;
    padding: 10vw 5vw;

    .title {
      display: grid;
      grid-template-columns: 15vw auto;

      .text {
        line-height: 50px;
        color: #363838;
        // font-family: "UTMAvoBold", sans-serif;
        font-size: 5vw;
        font-weight: bold;
        margin-left: 20px;
      }
    }

    ul {
      list-style-type: none;

      .subtitle {
        display: grid;
        grid-template-columns: 15vw auto;
        align-items: center;
        margin: 20px 0px;

        .list-1 {
          background: url($list-1) no-repeat top left;
          background-size: 100%;
          width: 15vw;
          height: 15vw;
        }
        .list-2 {
          background: url($list-2) no-repeat top left;
          background-size: 100%;
          width: 15vw;
          height: 15vw;
        }
        .list-3 {
          background: url($list-3) no-repeat top left;
          background-size: 100%;
          width: 15vw;
          height: 15vw;
        }

        .text {
          margin-left: 20px;
          color: #646d6d;
          font-size: 16px;
        }
      }
    }
  }
  &__section-4 {
    background: linear-gradient(90deg, #224082, #6380f8);
    height: 40vh;
    width: 100%;

    .title {
      text-align: center;
      font-size: 6vw;
      color: $foreground-color;
      padding: 20px;
    }
  }
  &__section-5 {
    text-align: center;
    display: inline-flex;
    position: relative;
    left: 15vw;
    top: 15px;
    margin-bottom: 80px;

    .bg-5 {
      background: url($bg5) no-repeat top left;
      width: 40vw;
      height: 28vw;
      position: relative;
      top: 5vw;
    }

    .text {
      text-align: start;

      .title {
        color: #4d4d4d;
        // font-family: "MyriadPro", sans-serif;
        text-align: start;
        line-height: 40px;
        font-weight: 800;
        font-size: 32px;
        line-height: 1.2;
        color: #202730;
      }
      .subtitle {
        color: #ffffff;
        font-weight: 500;
        line-height: 1.5;
        font-size: 18px;
      }
    }
  }
  &__section-6 {
    position: relative;
    text-align: center;

    .title {
      color: #111111;
      font-size: 20px;
      margin: 0 auto;
      margin-bottom: 20px;
      margin-left: 0;
      letter-spacing: 0.015em;
      line-height: 1.45;
    }

    .subtitle {
      color: #1c1c1c;
      // font-size: 3vw;
      // margin: 0px 0px 20px 0px;
      font-weight: 500;
      line-height: 1.3;
      font-size: 15px;
    }

    ul {
      display: inline-flex;
      list-style: none;

      li {
        p {
          color: #0071bc;
          // font-size: 3vw;
          margin-bottom: 20px;
          font-size: 18px;
          line-height: 1.2;
          font-family: "MyriadProBold", sans-serif;
        }
        .bg-logo-icon {
          display: inline-flex;
          margin: 0;
        }

        margin: 20px;
      }
    }
  }
  &__section-7 {
    background: url($support) no-repeat top left;
    background-position: center;
    background-size: cover;
    padding: 20px;
    min-height: 250px;
    text-align: center;

    ul {
      list-style-type: none;

      li {
        color: #f2f4f9;
        font-size: 4vw;
      }
    }

    .btn {
      .as-btn {
        border-radius: 22px;

        &:hover {
          color: #fff;
        }
      }
    }
  }
  &__section-8 {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;

    .hdsd-1,
    .hdsd-2,
    .hdsd-3,
    .hdsd-4,
    .hdsd-5,
    .hdsd-6 {
      width: 380px;
      height: 200px;
      margin: 0 auto;
    }
    .hdsd-1 {
      background: url($hdsd-1) no-repeat;
      background-size: contain;
    }
    .hdsd-2 {
      background: url($hdsd-2) no-repeat;
      background-size: contain;
    }
    .hdsd-3 {
      background: url($hdsd-3) no-repeat;
      background-size: contain;
    }
    .hdsd-4 {
      background: url($hdsd-4) no-repeat;
      background-size: contain;
    }
    .hdsd-5 {
      background: url($hdsd-5) no-repeat;
      background-size: contain;
    }
    .hdsd-6 {
      background: url($hdsd-6) no-repeat;
      background-size: contain;
    }
    .p2-1 {
      position: absolute;
      top: 0;
      background: url($p2-1) no-repeat;
      width: 100%;
      height: 1108px;
      left: 50px;
    }
    .p2-2 {
      position: absolute;
      top: 500px;
      background: url($p2-2) no-repeat;
      width: 100%;
      height: 100%;
    }
    .p2-3 {
      position: relative;
      background: url($p2-3) no-repeat;
      width: 380px;
      height: 450px;
    }

    .title {
      position: relative;
      display: inline-flex;
      margin: 20px 40px 10px 150px;
      font-family: "MyriadProBold", sans-serif;
      line-height: 22px;

      .text1 {
        text-align: center;
        font-size: 35px;
        color: #fff;
        font-weight: 500;
        text-align: start;
        max-width: 35vw;
        margin-top: 40px;
        padding: 20px;
      }
    }
    .timeline {
      display: block;
      position: relative;
      margin-top: 50px;

      .rs-timeline-align-alternate .rs-timeline-item-content {
        text-align: center;
        margin-top: -50px;
      }

      .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n + 1) {
        text-align: left;
        padding: 40px 0px;
      }
      .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n) {
        // width: unset;
        display: flex;
        text-align: center;
        padding: 40px 0;
      }

      &__dot {
        background-color: #fff;
        padding: 5px 10px;
        border-radius: 50%;
        box-shadow: 0px 0px 4px 4px #edeeef;
        text-align: center;
        margin: 0 auto;
        margin-left: -10px;
        position: absolute;
        background: #fff;
        top: 0;
        left: -2px;
        border: 2px solid #ddd;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 18px;
        padding-top: 9px;
        color: #999;
        margin-left: -13px;
      }
      p {
        padding: 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2;
        color: #363838;
        width: 380px;
        margin: 0 auto;
        text-align: center;
      }
    }
    .trial {
      position: relative;
      padding: 20px 0px;
    }
  }
}
/** End Dashboard **/

/** Data **/
.background__data {
  background-color: #e4e7ef;

  &__section-1 {
    .ptext .text1 span {
      font-family: "MyriadProBold", sans-serif;
    }
  }
  &__section-2 {
    ul {
      list-style-type: none;
      display: grid;
      padding-bottom: 40px;
      margin: 0;

      li {
        background-color: $foreground-color;
        border-top-right-radius: 25px;
        margin: 10px auto;
        width: 70vw;
        min-height: 160px;
        display: inline-table;
        text-align: center;

        .number {
          color: #fcfafa;
          background-color: #ab65cf;
          // font-family: "UTMAvoBold", sans-serif;
          padding: 5px 15px;
          font-size: 20px;
          font-weight: bold;
          position: relative;
          display: block;
          width: 42px;
          height: 42px;
        }

        .content {
          position: relative;
          top: -10px;
          display: inline-flex;
          align-items: center;

          .text {
            margin-left: 15px;
            text-align: start;
            line-height: 14px;

            .title {
              font-size: 20px;
              // font-family: "MyriadPro", sans-serif;
              color: #000;
              line-height: 1.7;
            }

            .subtitle {
              font-size: 14px;
            }
          }
        }

        .p2 {
          background: url($p2) no-repeat top left;
          background-size: 100%;
          width: 25vw;
          height: 12vw;
        }
        .p3 {
          background: url($p3) no-repeat top left;
          background-size: 100%;
          width: 7vw;
          height: 14vw;
        }
        .p4 {
          background: url($p4) no-repeat top left;
          background-size: 100%;
          width: 12vw;
          height: 12vw;
        }
      }
    }
  }

  &__section-3 {
    text-align: center;
    .note {
      color: #ff0000;
      font-size: 14px;
    }

    .link {
      font-size: 5vw;
      color: #0071bc;
      display: inline-flex;
      align-items: center;
      margin: 20px 0px;

      .bg-logo-icon {
        margin: 0px -3px 0px 15px;
      }

      .text {
        margin: 0px 10px;
        font-family: "MyriadProBold", sans-serif;
      }
    }

    .store {
      .apple-store,
      .google-store {
        width: 28vw;
        height: 8vw;
      }
    }
  }
}
/** End Data **/

/** Price **/
.background__price {
  &__section-2 {
    position: relative;
    .head {
      color: #0071bc;
      font-size: 5vw;
      text-align: center;
      font-family: "MyriadProBold", sans-serif;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        margin: 35px 0px;

        .card {
          margin: 10px 0px;
          width: 310px;
          margin: 0 auto;
          min-height: 400px;
          background-color: #e6e6e6;
          border-radius: 17px;
          padding: 30px;
          color: #000;

          .title {
            text-align: center;
            font-size: 28px;
            padding: 5px 0px 10px;
          }

          .price {
            text-align: center;
            padding: 5px 0px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            .number {
              font-size: 28px;
            }

            sub {
              font-size: 16px;
            }
          }
          .btn {
            // margin: 10px 0px;
          }
          .description {
            text-align: center;
            font-size: 14px;
          }
        }
      }
    }
  }
}
/** End Price **/

.background__contact {
  &__section-1 {
    .btn {
      .as-btn {
        border-radius: 22px;
      }
    }

    .card {
      position: relative;
      background-color: $foreground-color;
      color: #000;
      padding: 20px;
      width: 310px;
      border-radius: 14px;
      margin: 0 auto;
    }

    ul {
      li {
        margin: 20px 0px;
        display: inline-grid;
        align-items: center;
        grid-template-columns: 20px auto;

        p {
          margin-left: 15px;
        }
      }
    }

    .p-5 {
      background: url($p5) no-repeat top left;
      background-size: 100%;
      width: 70%;
      height: 100%;
      margin: 0 auto;
      top: 10vw;
      position: relative;
    }
  }
}

.background__dashboard {
  &__section-5 {
    .text {
      .title {
        line-height: 1.2;
      }
    }
  }
}

.background__stock {
  &__section-1 {
    .text1 h6 {
      font-family: "MyriadProBold", sans-serif;
    }
  }
}

/** Responsive **/
@media only screen and (max-width: 375px) {
  .background {
    &__section-8 {
      .rs-timeline-align-left .rs-timeline-item-tail {
        display: none;
      }
      .rs-timeline-align-left .rs-timeline-item-dot {
        display: none;
      }
    }
  }
}
@media only screen and (min-width: 376px) {
  .as-btn {
    font-size: 17px;
  }
}

@media (max-width: 479px) {
  .react-responsive-modal-overlay {
    padding: 0 !important;
  }
  .modal-require-trial {
    &__head {
      text-align: center;
    }
    &__body {
      padding: 0;
    }
  }
  .footer {
    padding: 20px 10px;
  }
  .background {
    position: relative;
    z-index: 1;

    &__section-1 {
      position: relative;
      min-height: 70vw;

      .text1 {
        text-align: center;
        font-size: 4vw;
        color: #fff;
        font-weight: 500;
        margin: 20px 10px;
      }

      .store {
        position: relative;
        display: flex;
        justify-content: center;

        .google-store {
          display: inline-block;
          background: url($google-store) no-repeat top left;
          width: 110px;
          height: 35px;
          cursor: pointer;
          z-index: 4;
        }
        .apple-store {
          display: inline-block;
          background: url($apple-store) no-repeat top left;
          width: 110px;
          height: 35px;
          cursor: pointer;
          z-index: 4;
          background-position: 0px 2px;
        }
      }

      .bg-1 {
        position: absolute;
        background: url($bg1) no-repeat;
        width: 100%;
        height: 1108px;
        top: -65px;
      }
      .bg-2 {
        position: absolute;
        top: 0;
        background: url($bg2) no-repeat;
        width: 100%;
        height: 100%;
      }
      .bg-4 {
        position: absolute;
        top: 0;
        background: url($bg4) no-repeat;
        width: 100%;
        height: 1226px;
        right: -90px;
        top: 120px;
      }

      .ptext {
        padding: 20px 40px;
      }
    }
    &__section-2 {
      position: relative;
      display: inline-flex;
      min-height: 300px;

      .bg-3 {
        display: inline-flex;
        position: relative;
        // left: 19vw;
        // top: 5vh;
        background: url($bg3) no-repeat;
        width: 95vw;
        height: 100%;

        .list-card {
          list-style-type: none;
          padding: 0;

          .card {
            position: relative;
            width: 34vw;
            height: 34vw;
            background-color: $foreground-color;
            border-radius: 14px;
            margin: 20px 10px;
            padding: 10px;
            left: 20vw;
            top: 10vw;

            p {
              font-weight: bold;
              margin-top: 5px;
            }
            span {
              font-size: 12px;
            }
          }
        }

        .bg-6 {
          background: url($bg6) no-repeat top left;
          width: 12vw;
          height: 14vw;
        }
        .bg-7 {
          background: url($bg7) no-repeat top left;
          width: 12vw;
          height: 12vw;
        }
        .bg-8 {
          background: url($bg8) no-repeat top left;
          width: 12vw;
          height: 12vw;
        }
        .bg-9 {
          background: url($bg9) no-repeat top left;
          width: 12vw;
          height: 12vw;
        }
      }
    }
    &__section-3 {
      position: relative;
      padding: 10vw 5vw;

      .title {
        display: grid;
        grid-template-columns: 15vw auto;

        .text {
          line-height: 50px;
          color: #363838;
          // font-family: "UTMAvoBold", sans-serif;
          font-size: 4vw;
          font-weight: bold;
          margin-left: 20px;
        }
      }

      ul {
        list-style-type: none;

        .subtitle {
          display: grid;
          grid-template-columns: 15vw auto;
          align-items: center;
          margin: 20px 0px;

          .list-1 {
            background: url($list-1) no-repeat top left;
            background-size: 100%;
            width: 15vw;
            height: 15vw;
          }
          .list-2 {
            background: url($list-2) no-repeat top left;
            background-size: 100%;
            width: 15vw;
            height: 15vw;
          }
          .list-3 {
            background: url($list-3) no-repeat top left;
            background-size: 100%;
            width: 15vw;
            height: 15vw;
          }

          .text {
            margin-left: 20px;
            color: #646d6d;
            font-size: 16px;
          }
        }
      }
    }
    &__section-4 {
      background: linear-gradient(90deg, #224082, #6380f8);
      height: 40vh;
      width: 100%;

      .title {
        text-align: center;
        font-size: 6vw;
        color: $foreground-color;
        padding: 20px;
      }
    }
    &__section-5 {
      left: 20vw;

      .text {
        max-width: 100%;

        .subtitle {
          color: #4f4f4f;
        }
      }
      .bg-5 {
        display: none;
      }
    }
    &__section-8 {
      .title {
        margin: 20px 40px 10px 40px;
        .text1 {
          max-width: unset;
          margin-top: 0;
        }
      }
      .p2-3 {
        width: 360px;
        height: 250px;
      }
      .rs-timeline-align-left .rs-timeline-item-tail {
        left: 20px;
      }
      .rs-timeline-align-left .rs-timeline-item-dot {
        left: 16px;
      }
      .hdsd-1,
      .hdsd-2,
      .hdsd-3,
      .hdsd-4,
      .hdsd-5,
      .hdsd-6 {
        width: 300px;
        height: 180px;
        margin: 0 auto;
      }
      .timeline p {
        width: 300px;
        margin: 0 auto;
      }
    }
  }
  .background__dashboard {
    min-height: unset;

    &__section-1 {
      .bg-2 {
        width: 160vw;
        height: 80vw;
        display: none;
      }
    }
  }
  .background__data {
    background-color: #e4e7ef;

    &__section-1 {
      min-height: 85vw;
    }

    &__section-2 {
      ul {
        li {
          width: 96vw;

          .content {
            margin: 0 auto;
            width: 70vw;
          }

          .p2 {
            width: 12vw;
            height: 12vw;
          }
          .p3 {
            width: 9vw;
            height: 13vw;
            margin: 0 1vw;
          }
          .p4 {
            width: 12vw;
            height: 12vw;
          }
        }
      }
    }

    &__section-3 {
      .store {
        .apple-store,
        .google-store {
          width: 105px;
          height: 32px;
        }
      }
    }
  }
}
@media (min-width: 480px) and (max-width: 575px) {
  .modal-require-trial {
    &__head {
      text-align: center;
    }
    &__body {
      padding: 0;
    }
  }
  .background {
    &__section-1 {
      position: relative;
      min-height: 75vw;

      .store {
        .apple-store {
          width: 125px;
          height: 45px;
        }
        .google-store {
          width: 125px;
          height: 45px;
        }
      }

      .ptext {
        padding: 20px 60px;
      }
    }
    &__section-2 {
      .bg-3 {
        padding: 30px;

        .list-card {
          .card {
            position: relative;
            width: 30vw;
            height: 30vw;
            background-color: white;
            border-radius: 14px;
            margin: 20px 10px;
            padding: 10px;
            left: 15vw;
            top: 7vw;
          }
        }
      }
    }
    &__section-5 {
      top: 5vw;
      height: 55vw;

      .bg-5 {
        top: 20vw;
        position: relative;
        bottom: 0;
        right: 0;
        left: -10vw;
      }
    }
    &__section-7 {
      ul {
        li {
          color: #f2f4f9;
          font-size: 3vw;
        }
      }
    }
    &__section-8 {
      .title {
        margin: 20px 40px 10px 60px;
        .text1 {
          max-width: unset;
          margin-top: 0;
        }
      }
      .p2-3 {
        width: 380px;
        height: 250px;
      }
      .rs-timeline-align-left .rs-timeline-item-tail {
        left: 40px;
      }
      .rs-timeline-align-left .rs-timeline-item-dot {
        left: 36px;
      }
      .hdsd-1,
      .hdsd-2,
      .hdsd-3,
      .hdsd-4,
      .hdsd-5,
      .hdsd-6 {
        width: 320px;
        height: 180px;
        margin: 0 auto;
      }
      .timeline p {
        width: 320px;
        margin: 0 auto;
      }
    }
  }
  .background__dashboard {
    &__section-1 {
      .bg-1 {
        width: 135%;
        height: 120vw;
        left: -30%;
        top: 0;
      }
      .bg-2 {
        width: 140%;
        height: 100%;
        left: -40vw;
      }
    }
  }
  .background__data {
    background-color: #e4e7ef;

    &__section-2 {
      ul {
        li {
          width: 80vw;

          .content {
            margin: 0 auto;
            width: 70vw;
            height: 150px;
            margin: 0 15vw;
          }

          .p2 {
            width: 9vw;
            height: 12vw;
          }
          .p3 {
            width: 7vw;
            height: 10vw;
            margin: 0 1vw;
          }
          .p4 {
            width: 9vw;
            height: 12vw;
          }
        }
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767px) {
  .background {
    &__section-1 {
      position: relative;
      min-height: 75vw;

      .store {
        .apple-store {
          width: 150px;
          height: 55px;
        }
        .google-store {
          width: 150px;
          height: 54px;
        }
      }

      .bg-4 {
        right: -150px;
        top: 165px;
      }

      .ptext {
        padding: 20px 80px;
      }
    }
    &__section-2 {
      .bg-3 {
        width: 100vw;
        padding: 20px;
        height: 80vw;

        .list-card {
          .card {
            position: relative;
            width: 24vw;
            height: 24vw;
            background-color: white;
            border-radius: 14px;
            margin: 20px 10px;
            padding: 20px;
            left: 18vw;
            top: 6vw;

            .bg-6,
            .bg-7,
            .bg-8,
            .bg-9 {
              width: 7vw;
              height: 7vw;
            }

            span {
              font-size: 14px;
            }
          }
        }
      }
    }
    &__section-3 {
      .title .text {
        font-size: 3.5vw;
      }
    }
    &__section-5 {
      left: 15vw;
      height: 50vw;

      .text {
        max-width: 32vw;

        .title {
          font-size: 35px;
        }
        .subtitle {
          font-size: 16px;
        }
      }

      .bg-5 {
        width: 50vw;
        height: 35vw;
      }
    }
    &__section-6 {
      .title {
        font-size: 25px;
      }
      .subtitle {
        font-size: 20px;
      }
      .icon p {
        font-size: 20px;
      }
    }
    &__section-7 {
      ul {
        li {
          color: #f2f4f9;
          font-size: 2.5vw;
        }
      }
    }
    &__section-8 {
      .title {
        margin: 20px 40px 10px 80px;
        .text1 {
          max-width: unset;
          margin-top: 0;
        }
      }
      .p2-3 {
        width: 380px;
        height: 350px;
      }
      .rs-timeline-align-left .rs-timeline-item-tail {
        left: 40px;
      }
      .rs-timeline-align-left .rs-timeline-item-dot {
        left: 36px;
      }
      .hdsd-1,
      .hdsd-2,
      .hdsd-3,
      .hdsd-4,
      .hdsd-5,
      .hdsd-6 {
        width: 320px;
        height: 180px;
        margin: 0 auto;
      }
      .timeline p {
        width: 320px;
        margin: 0 auto;
      }
    }
  }
  .background__dashboard {
    &__section-1 {
      .bg-1 {
        width: 120%;
        height: 100%;
        left: -5%;
        top: 0;
      }
      .bg-2 {
        width: 120%;
        left: -20%;
      }
    }
  }
  .background__data {
    background-color: #e4e7ef;

    &__section-2 {
      ul li {
        width: 420px;

        .content {
          margin: 0 auto;
          width: 70vw;
          margin: 0 10vw;
        }

        .p2 {
          width: 8vw;
          height: 12vw;
        }
        .p3 {
          width: 6vw;
          height: 12vw;
          margin: 0px 1vw;
        }
        .p4 {
          width: 8vw;
          height: 12vw;
        }
      }
    }
  }
  .background__contact {
    &__section-1 {
      min-height: 100vh;

      .text1 {
        span {
          font-size: 18px;
        }
      }

      ul {
        list-style-type: none;
        padding: 20px;
        min-height: 200px;

        li {
          font-size: 16px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .toolbar__navigation--menu {
    display: none !important;
  }

  .hamburger-icon {
    position: absolute;
    right: 0;

    .container {
      display: inline-block;
      cursor: pointer;
    }

    .logo {
      width: 34px;
      height: 30px;
      background: url($hamburgerPath) no-repeat;
      background-position: center center;
      display: inline-block;
      margin: 0 auto;
      margin: 0px 10px;
      cursor: pointer;
    }

    &--menu {
      // font-family: "UTMAvoBold", sans-serif;
      font-size: 1rem;
      font-weight: 600;

      li {
        padding: 10px 12px;
      }

      li a {
        text-decoration: none;
        color: $primary-color;

        &:hover {
          color: $active-text;
        }
      }

      li a.active {
        color: $active-text;
      }
    }
  }

  .language li {
    padding: 0;
  }

  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }

  .background__contact {
    &__section-1 {
      min-height: 100vh;

      .text1 {
        span {
          font-size: 18px;
        }
      }

      ul {
        list-style-type: none;
        padding: 20px;
        min-height: 200px;

        li {
          font-size: 16px;
        }
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {
  .btn {
    .as-btn {
      font-size: 1.5vw !important;
      padding: 15px 55px !important;
    }
  }
  .toolbar {
    .toolbar__navigation {
      padding: 0 1vw !important;
    }
  }
  .background {
    &__section-1 {
      position: relative;
      min-height: 55vw;

      .text1 {
        text-align: left;
        font-size: 2vw;
        padding: 5vw 15vw 0px 15vw;

        h6 {
          // font-family: "UTMAvoBold", sans-serif;
          font-size: 26px;
        }
        span {
          font-size: 18px;
        }
      }

      .store {
        justify-content: start;
        padding: 0 15vw;

        .apple-store,
        .google-store {
          width: 150px;
          height: 50px;
        }
      }

      .bg-1 {
        height: 1208px;
        top: 40px;
      }

      .bg-4 {
        height: 60vw;
        right: -45vw;
        top: 0;
      }
    }
    &__section-2 {
      min-height: 55vw;

      .bg-3 {
        width: 45vw;

        .list-card {
          .card {
            width: 17vw;
            height: 17vw;
            left: 12vw;
            padding: 15px;
            top: 4vw;

            .bg-6,
            .bg-7,
            .bg-8,
            .bg-9 {
              width: 4vw;
              height: 4vw;
            }

            span {
              font-size: 13px;
            }
          }
        }
      }

      .title {
        max-width: 36vw;
        padding: 0 20px;
        margin: 0 10vw;

        & > .text {
          // font-family: "UTMAvoBold", sans-serif;
          font-size: 18px;
          color: #363838;
        }

        ul {
          list-style-type: none;

          li {
            line-height: 20px;
            margin: 20px 0px;
            color: #646d6d;
            font-size: 16px;
          }
        }
      }
    }
    &__section-4-title {
      // font-family: "MyriadPro", sans-serif;
      font-size: 30px;
      font-weight: 400;
      color: #212121;
      text-align: center;
      background-color: $foreground-color;
      margin-bottom: 5px;
    }
    &__section-4 {
      height: 45vh;
    }
    &__section-5 {
      // height: 55vw;
      .text {
        max-width: 32vw;

        .title {
          font-size: 6vw;
          line-height: 50px;
        }
        .subtitle {
          // font-size: 16px;
          // line-height: 16px;
        }
      }

      .bg-5 {
        width: 50vw;
        height: 35vw;
      }
    }
    &__section-8 {
      .hdsd-1,
      .hdsd-2,
      .hdsd-3,
      .hdsd-4,
      .hdsd-5,
      .hdsd-6 {
        width: 340px;
        height: 190px;
        margin: 0 auto;
      }
      .timeline p {
        width: 340px;
        margin: 0 auto;
      }
    }
  }
  .background__data {
    &__section-1 {
      min-height: 75vw;

      .bg-1 {
        height: 120vw;
        top: 120px;
      }

      .p-1 {
        width: 100%;
        top: 10vw;
      }

      .ptext {
        position: relative;

        .text1 {
          line-height: 35px;
          padding: 5vw 30vw 0px 3vw;
          margin: 0 0;

          span {
            font-size: 26px;
          }
        }
      }
    }
    &__section-2 {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;

      ul {
        height: fit-content;

        li {
          // width: 400px;

          .content {
            grid-template-columns: 6vw auto;
            margin: 0px 5px 0px 65px;

            .p2 {
              width: 6vw;
              height: 8vw;
            }
            .p3 {
              width: 4vw;
              height: 8vw;
              margin: 0 1vw;
            }
            .p4 {
              width: 6vw;
              height: 8vw;
            }
          }
        }
      }

      .p-1 {
        top: 3vw;
      }
    }

    &__section-3 {
      background-color: $foreground-color;
      position: relative;
      padding: 40px 0px 40px 0px;
      width: 100%;

      ul {
        list-style-type: none;
      }
    }
  }
  .background__price {
    &__section-2 {
      ul {
        display: inline-flex;
        margin: 0 auto;

        li {
          margin: 35px 0.5vw;
          .card {
            width: 32vw;
            padding: 4vw 0px;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .modal-require-trial {
    &__body {
      .contact .text {
        font-size: 28px;
      }
    }
  }
  .toolbar {
    .toolbar__navigation {
      padding: 0 10vw;
      height: 70px;
    }
  }
  .btn {
    margin: 25px 0px;

    .as-btn {
      // font-size: 1.3vw;
      // padding: 15px 50px;
      padding: 0.6em 1.24em;
      font-size: 13px;
      line-height: 1.4;
    }
  }
  .footer ul li {
    font-size: 16px;
  }
  .background {
    &__section-1 {
      .text1 {
        .group-text-icon {
          display: inline-flex;
          margin-left: -85px;
          align-items: center;
        }
      }
    }
    &__section-5 {
      left: 15vw;

      .text {
        max-width: 32vw;
      }
    }
    &__section-6 {
      .title {
        font-size: 35px;
      }
      .subtitle,
      .icon p {
        font-size: 25px;
      }
    }
    &__section-7 {
      ul {
        li {
          color: #f2f4f9;
          font-size: 20px;
          line-height: 1.7;
        }
      }
    }
    &__section-8 {
      .title {
        h6 {
          font-size: 26px;
          line-height: 30px;
        }
      }
    }
  }
  .background__dashboard {
    &__section-1 {
      min-height: 50vw;
      // min-height: unset !important;
    }
    &__section-5 {
      min-height: 42vw;

      .text {
        .title {
          font-size: 45px;
        }
        .subtitle {
          line-height: 1.2;
          font-size: 18px;
        }
      }
      .bg-5 {
        top: 0;
      }
    }
  }
  .background__data {
    &__section-1 {
      .ptext .text1 {
        line-height: 1.7;

        span {
          font-size: 30px;
        }
      }
    }
    &__section-2 {
      ul {
        list-style-type: none;
        display: grid;
        padding-bottom: 40px;
        margin: 0;

        li {
          background-color: $foreground-color;
          border-top-right-radius: 25px;
          margin: 10px auto;
          width: 50vw;
          min-height: 210px;
          display: inline-table;
          text-align: center;

          .number {
            color: #fcfafa;
            background-color: #ab65cf;
            // font-family: "UTMAvoBold", sans-serif;
            padding: 10px 20px;
            font-size: 20px;
            font-weight: bold;
            position: relative;
            display: block;
            width: 55px;
            height: 50px;
          }

          .content {
            display: inline-flex;
            align-items: center;
            width: 35vw;
            margin: 0px 5vw;

            .text {
              margin-left: 15px;
              text-align: start;
              line-height: 14px;

              .title {
                font-size: 24px;
                // font-family: "MyriadPro", sans-serif;
                color: #000;
                line-height: 1.2;
              }

              .subtitle {
                font-size: 15px;
                line-height: 16px;
              }
            }
          }

          .p2 {
            background: url($p2) no-repeat top left;
            background-size: 100%;
            width: 25vw;
            height: 12vw;
          }
          .p3 {
            background: url($p3) no-repeat top left;
            background-size: 100%;
            width: 7vw;
            height: 14vw;
          }
          .p4 {
            background: url($p4) no-repeat top left;
            background-size: 100%;
            width: 12vw;
            height: 12vw;
          }
        }
      }
    }
    &__section-3 {
      background-color: transparent;
      padding: 0px 0px 40px 0px;

      .link {
        font-size: 35px;
      }

      .store {
        .apple-store {
          background-position: 0px 4px;
        }
        .apple-store,
        .google-store {
          width: 200px;
          height: 55px;
        }
      }
    }
  }
  .background__price {
    &__section-2 {
      text-align: center;

      .head {
        font-size: 35px;
      }
      ul {
        display: inline-flex;
        margin: 0 auto;

        li {
          margin: 35px 0.5vw;

          .card {
            max-width: 32vw;

            .title {
              font-size: 2vw;
            }

            .price {
              .number {
                font-size: 3vw;
              }

              sub {
                font-size: 16px;
              }
            }
            .btn {
              margin: 0 0 25px 0px;
            }
          }
        }
      }
    }
  }
  .background__contact {
    &__section-1 {
      min-height: 50vw;

      ul li {
        margin: 15px 0px;
        grid-template-columns: 40px auto;
      }
      .bg-2 {
        display: inline-flex;
        align-items: center;

        .text1 {
          padding: unset;
          position: relative;
          // top: -10vw;

          span {
            margin: 20px 0px;
            font-size: 22px;
            font-size: 2vw;
          }

          .card {
            width: 400px;
            top: -5vw;
            padding: 2vw;
            left: 2vw;
            color: #fff;
            background-color: transparent;

            li {
              display: grid;
              p {
                font-size: 16px;
                line-height: 1.7;
              }
            }
          }
        }
        .btn {
          margin: 20px 0px;
        }
      }
    }
  }
  .background__stock {
    &__section-1 {
      min-height: 50vw;

      .bg-2 {
        z-index: -1;
      }
      .text1 {
        margin: 0;
      }
    }
  }
}
@media (min-width: 980px) {
  .background__dashboard {
    &__section-5 {
      .text {
        .title {
          font-size: 60px;
        }
      }
    }
  }
}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {
  .background {
    &__section-1 {
      position: relative;
      // min-height: 60vw;

      .text1 {
        text-align: left;
        font-size: 2vw;
        padding: 5vw 15vw 0px 15vw;

        h6 {
          // font-family: "UTMAvoBold", sans-serif;
          font-size: 26px;
        }
        span {
          font-size: 18px;
        }
      }

      .store {
        justify-content: start;
        padding: 0 15vw;

        .apple-store,
        .google-store {
          width: 150px;
          height: 50px;
        }
      }

      .bg-1 {
        height: 1208px;
        top: 40px;
      }

      .bg-4 {
        height: 60vw;
        right: -45vw;
        top: 0;
      }
    }
    &__section-2 {
      min-height: 47vw;

      .bg-3 {
        width: 45vw;

        .list-card {
          .card {
            width: 15vw;
            height: 15vw;
            left: 10vw;
            padding: 20px;
            top: 3vw;

            .bg-6,
            .bg-7,
            .bg-8,
            .bg-9 {
              width: 5vw;
              height: 5vw;
            }

            p {
              font-size: 18px;
            }
            span {
              font-size: 14px;
            }
          }
        }
      }

      .title {
        max-width: 36vw;
        padding: 0 20px;
        margin: 0 10vw;

        & > .text {
          // font-family: "UTMAvoBold", sans-serif;
          font-size: 22px;
          color: #363838;
        }

        ul {
          list-style-type: none;

          li {
            line-height: 20px;
            margin: 20px 0px;
            color: #646d6d;
            font-size: 18px;
          }
        }
      }
    }
    &__section-4-title {
      // font-family: "MyriadPro", sans-serif;
      font-size: 30px;
      font-weight: 400;
      color: #212121;
      text-align: center;
      background-color: $foreground-color;
      margin-bottom: 5px;
    }
    &__section-4 {
      height: 45vh;
    }
    &__section-7 {
      ul li {
        font-size: 2vw;
      }
    }
  }
  .background__dashboard {
    &__section-1 {
      .bg-2 {
        top: -7vw;
      }
    }
    &__section-5 {
      min-height: 35vw;
      .bg-5 {
        top: 5vw;
      }
    }
  }
  .background__data {
    &__section-1 {
      min-height: 60vw;

      .bg-1 {
        height: 120vw;
        top: 0;
      }

      .p-1 {
        width: 100%;
        top: 10vw;
      }

      .ptext {
        position: relative;

        .text1 {
          line-height: 35px;
          padding: 5vw 35vw 0px 6vw;
          margin: 0 0;

          span {
            font-size: 30px;
          }
        }
      }
    }
    &__section-2 {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;

      ul {
        height: fit-content;

        li {
          width: 43vw;

          .content {
            margin: 0px 5px 0px 65px;

            .p2 {
              width: 6vw;
              height: 8vw;
            }
            .p3 {
              width: 4vw;
              height: 8vw;
              margin: 0 1vw;
            }
            .p4 {
              width: 6vw;
              height: 8vw;
            }
          }
        }
      }

      .p-1 {
        top: 3vw;
      }
    }
    &__section-3 {
      position: relative;
      width: 100%;

      ul {
        list-style-type: none;
      }
      .link {
        font-size: 2.5vw;
      }
    }
  }
  .background__stock {
    &__section-1 {
      min-height: 50vw;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .background {
    &__section-1 {
      position: relative;
      min-height: 50vw;

      .text1 {
        text-align: left;
        font-size: 2vw;
        padding: 5vw 15vw 0px 15vw;

        h6 {
          // font-family: "UTMAvoBold", sans-serif;
          font-size: 26px;
        }
        span {
          font-size: 18px;
        }
      }

      .store {
        justify-content: start;
        padding: 0 15vw;

        .apple-store,
        .google-store {
          width: 150px;
          height: 50px;
        }
      }

      .bg-1 {
        height: 100vw;
      }

      .bg-4 {
        height: 60vw;
        right: -45vw;
        top: 0;
      }
    }
    &__section-2 {
      min-height: 40vw;

      .bg-3 {
        width: 45vw;

        .list-card {
          .card {
            width: 12vw;
            height: 12vw;
            left: 10vw;
            padding: 20px;
            top: 4vw;

            .bg-6,
            .bg-7,
            .bg-8,
            .bg-9 {
              width: 4vw;
              height: 4vw;
            }

            span {
              font-size: 14px;
            }
          }
        }
      }

      .title {
        max-width: 36vw;
        padding: 0 20px;
        margin: 0 10vw;

        & > .text {
          // font-family: "UTMAvoBold", sans-serif;
          font-size: 22px;
          color: #363838;
        }

        ul {
          list-style-type: none;

          li {
            line-height: 20px;
            margin: 20px 0px;
            color: #646d6d;
            font-size: 18px;
          }
        }
      }
    }
    &__section-4-title {
      // font-family: "MyriadPro", sans-serif;
      font-size: 30px;
      font-weight: 400;
      color: #212121;
      text-align: center;
      background-color: $foreground-color;
      margin-bottom: 5px;
    }
    &__section-4 {
      height: 45vh;
    }
  }
  .background__dashboard {
    &__section-1 {
      .bg-2 {
        top: -12vw;
        height: 125%;
      }
    }
    &__section-5 {
      top: 4vw;
      min-height: 32vw;
    }
  }
  .background__data {
    &__section-1 {
      min-height: 55vw;

      .bg-1 {
        height: 90vw;
      }

      .p-1 {
        width: 100%;
        top: 10vw;
      }

      .ptext {
        position: relative;

        .text1 {
          padding: 3vw 47vw 0px 6vw;
          margin: 0 0;

          span {
            font-size: 30px;
          }
        }
      }
    }
    &__section-2 {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;

      ul {
        height: fit-content;

        li {
          width: 40vw;

          .content {
            // margin: 0px 5px 0px 65px;
            height: 13vw;
            top: -1.8vw;

            .text {
              .title {
                font-size: 30px;
              }
              .subtitle {
                font-size: 18px;
                line-height: 1.2;
              }
            }

            .p2 {
              width: 5vw;
              height: 7vw;
            }
            .p3 {
              width: 3vw;
              height: 7vw;
              margin: 0 1vw;
            }
            .p4 {
              width: 5vw;
              height: 7vw;
            }
          }
        }
      }

      .p-1 {
        top: 3vw;
      }
    }
    &__section-3 {
      position: relative;
      width: 100%;
      .note {
        font-size: 15px;
      }
      ul {
        list-style-type: none;
      }
      .link {
        font-size: 35px;
      }
    }
  }
  .background__price {
    &__section-2 {
      .head {
        font-size: 35px;
        line-height: 1.7;
      }

      ul li {
        .card {
          min-width: 360px;

          .title {
            font-size: 35px;
            line-height: 1.7;
          }
          .price {
            .number {
              font-size: 35px;
              line-height: 1.5;
            }
            sub {
              font-size: 18px;
            }
          }

          .description {
            font-size: 18px;
          }
        }
      }
    }
  }
  .background__stock {
    &__section-1 {
      min-height: 42vw;

      .bg-2 {
        top: -20%;
        height: 125%;
      }

      .text1 {
        font-size: unset;

        h6 {
          font-size: 35px;
          line-height: 1.2;
        }
        span {
          font-size: 20px;
          line-height: 1.7;
        }
        .group-text-icon {
          // margin-left: -6vw;

          .bg-logo-icon {
            margin-left: -15px;
            margin-right: 15px;

            .bg {
              width: 85px;
              height: 82px;
              .stock-logo {
                width: 45px;
                height: 70px;
              }
            }
          }
        }
      }
      .store {
        .apple-store,
        .google-store {
          width: 200px;
          height: 55px;
        }

        .apple-store {
          margin: 2px 0px;
        }
      }
    }
    &__section-2 {
      min-height: 42vw;

      .bg-3 {
        .list-card {
          .card {
            width: 15vw;
            height: 15vw;
            padding: 3vw;

            p {
              font-size: 18px;
              line-height: 1.7;
            }
          }
        }
      }
      .title {
        & > .text {
          font-size: 30px;
          line-height: 1.7;
        }
        ul li {
          line-height: 1.7;
        }
      }
    }
  }
  .background__help {
    &__section-8 {
      .text1 {
        h6 {
          font-size: 35px;
          line-height: 1.2;
        }
      }
    }
  }
}

@media (min-width: 1980px) {
  .background__dashboard {
    &__section-1 {
      .bg-2 {
        top: -8vw;
      }
    }
    &__section-5 {
      // min-height: 35vw;
      top: 2vw;
      height: 25vw;
      min-height: unset;
    }
    &__section-6 {
      p {
        color: #eff0f8 !important;
      }
    }
  }
  .background__data {
    &__section-1 {
      min-height: 55vw;

      .bg-1 {
        height: 90vw;
      }

      .p-1 {
        width: 100%;
        top: 10vw;
      }

      .ptext {
        position: relative;

        .text1 {
          padding: 3vw 47vw 0px 6vw;
          margin: 0 0;

          span {
            font-size: 35px;
          }
        }
      }
    }
    &__section-2 {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;

      ul {
        height: fit-content;

        li {
          width: 35vw;

          .content {
            // margin: 0px 5px 0px 65px;
            height: 13vw;
            top: -1.8vw;

            .text {
              .title {
                font-size: 35px;
                margin: 0px 0px 1.5vw 0px;
              }
              .subtitle {
                font-size: 25px;
                line-height: 1.2;
              }
            }

            .p2 {
              width: 5vw;
              height: 7vw;
            }
            .p3 {
              width: 3vw;
              height: 7vw;
              margin: 0 1vw;
            }
            .p4 {
              width: 5vw;
              height: 7vw;
            }
          }
        }
      }
      .p-1 {
        top: 3vw;
      }
    }
  }
  .background__stock {
    &__section-1 {
      .text1 {
        h6 {
          font-size: 38px;
          line-height: 1.2;
        }
        span {
          font-size: 25px;
          line-height: 1.7;
        }
      }
    }
    &__section-2 {
      min-height: 35vw;
      .bg-3 {
        .list-card {
          .card {
            width: 12vw;
            height: 12vw;
            padding: 2vw;

            p {
              font-size: 20px;
              line-height: 1.7;
            }
            span {
              font-size: 18px;
              line-height: 1.7;
            }
          }
        }
      }
    }
  }
  .background__help {
    &__section-8 {
      .text1 {
        h6 {
          font-size: 38px;
          line-height: 1.2;
        }
      }
    }
  }
}
